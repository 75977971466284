import React from 'react';
import { useLocation, RouteComponentProps } from 'react-router-dom';
import loadable from '@loadable/component';
import { Header, PolestarApp, PageContent } from '@polestar/component-warehouse-react';
import {
  ErrorBoundary,
  AppRouter,
  RouteDefinition,
  Themes,
  useMarket,
  useMarketSelector,
  ConnectedMarketSelector,
  ConnectedDrawer,
  Domain,
  PageFooter
} from '@polestar/web3-core-react';

import routes from '~routes/definition';

const LoadableView = loadable((props: { view: string }) => import(`./views/${props.view}/index`));

const LoadableRouter = () => {
  const getView = (routeDefinition: RouteDefinition | null, routeProps: RouteComponentProps) => (
    <LoadableView {...routeProps} view={routeDefinition?.view || 'NotFoundView'} />
  );
  const getNotFoundView = (
    _routeDefinition: RouteDefinition | null,
    routeProps: RouteComponentProps
  ) => <LoadableView {...routeProps} view="NotFoundView" />;

  return <AppRouter routes={routes} getView={getView} getNotFoundView={getNotFoundView} />;
};

const App = () => {
  const { currentMarket, availableMarkets, loading, error } = useMarket();

  let { pathname } = useLocation();
  const marketContext = useMarketSelector();

  if (currentMarket) {
    // MarketSelector already prefix routes with a locale.
    pathname = pathname.replace(`/${currentMarket.locale}`, '');
  }

  if (error) {
    return <>Something went wrong.</>;
  }

  return (
    <ErrorBoundary>
      <PolestarApp rootSelector="#root" theme={Themes.default}>
        <Header
          logoProps={{
            as: 'a',
            href: currentMarket ? `/${currentMarket.locale}` : '/',
            target: '_self',
            rel: 'noopener'
          }}
        />

        {!loading && (
          <>
            <ConnectedDrawer
              enableLogin
              domain={Domain.WWW}
              market={currentMarket || undefined}
              queryContext={{ dato: true }}
            />
            <ConnectedMarketSelector
              path={pathname}
              onClose={marketContext.onClose}
              visible={marketContext.visible}
              queryContext={{ dato: true }}
              markets={availableMarkets}
            />
          </>
        )}

        <PageContent>
          <LoadableRouter />
        </PageContent>

        {!loading && <PageFooter />}
      </PolestarApp>
    </ErrorBoundary>
  );
};

export default App;
