import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import getRuntimeConfig from '~config/index';
import { printBuildInfo, BuildParams } from '~src/utils';
import AppContextProvider from './contexts/AppContextProvider';
import apolloClient from './apollo/apollo-client';
import App from './App';

const config = getRuntimeConfig();

printBuildInfo(config as BuildParams);

// Init Sentry
Sentry.init({
  dsn: config.sentry.dsn,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [/\/\/localhost/]
    })
  ],
  tracesSampleRate: config.sentry.tracesSampleRate,
  environment: config.sentry.environment,
  release: config.sentry.release
});

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <BrowserRouter>
      <AppContextProvider config={config}>
        <App />
      </AppContextProvider>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById('root')
);
