// Application specific configuration (environment based)
// We expose a function instead of an object to make sure dotenv has been called before the config itself is defined
// Otherwise all process.env.XXX are undefined.
const getRuntimeConfig = () => ({
  application: {
    // Define parameters specific to the application itself (environment-based or not)
    baseUrl: <string>process.env.APP_BASE_URL,
    // Domain for authentication cookie
    domain: <string>process.env.APP_DOMAIN
  },
  api: {
    // Define parameters specific to the API (environment-based or not)
    global: <string>'https://gql-api.omur-yildirim-playground.plstr.io/',
    china: <string>process.env.API_CHINA_BASE_URL,
    region: <'global' | 'china'>process.env.API_REGION || 'global'
  },
  dato: {
    // Define parameters specific to the content API (environment-based or not)
    assetUrl: <string>process.env.DATO_ASSET_BASE_URL,
    baseUrl: <string>process.env.DATO_BASE_URL,
    previewUrl: <string>process.env.DATO_PREVIEW_BASE_URL,
    token: <string>process.env.DATO_TOKEN
  },
  sentry: {
    // Various Sentry parameters
    dsn: <string>process.env.SENTRY_DSN,
    environment: <string>process.env.SENTRY_STAGE,
    release: <string>process.env.SENTRY_RELEASE,
    tracesSampleRate: Number(process.env.SENTRY_SAMPLE_RATE) || 0
  },
  repositoryInfo: {
    build: <string>process.env.BITBUCKET_BUILD_NUMBER,
    commit: <string>process.env.BITBUCKET_COMMIT,
    tag: <string>process.env.BITBUCKET_TAG
  }
});

export default getRuntimeConfig;
