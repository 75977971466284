import { getDefaultPolestarApolloClient } from '@polestar/graphql-client';
import getRuntimeConfig from '~config/index';

const config = getRuntimeConfig();

// check if we are to create the dato apollo client for the preview site or the regular site
let datoPreviewToken = '';
try {
  datoPreviewToken = new URLSearchParams(window.location.search).get('preview') || '';
} catch (error) {} /* eslint-disable-line no-empty */

const defaultUrl: string = config.api[config.api.region];
const clientConfig = {
  defaultUrl,
  datoUrl: datoPreviewToken ? <string>config.dato.previewUrl : <string>config.dato.baseUrl,
  datoToken: datoPreviewToken || <string>config.dato.token
};

const client = getDefaultPolestarApolloClient(clientConfig);

export default client;
