import React from 'react';

import {
  AuthenticationProvider,
  MarketSelectorProvider,
  MarketProvider
} from '@polestar/web3-core-react';

/**
 * Implement all the different context provider here, they will be passed to the application
 */
const AppContextProvider = ({ config, ...props }: any) => {
  return (
    <MarketProvider>
      <MarketSelectorProvider>
        <AuthenticationProvider {...props} config={config.application} />
      </MarketSelectorProvider>
    </MarketProvider>
  );
};

export default AppContextProvider;
